import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import Table from '@components/tables/TableImagen'
import ModalCliente from '../includes/ModalCliente'
import ModalPay from '../includes/ModalPay'
import { XMarkIcon } from "@heroicons/react/24/outline";
import { productosData, setInd, resetData, clientesData, clienteItem, clientesItem, setLciudad  } from '../../reducers/clienteSlice'
import { catesItems } from '../../reducers/cateSlice'
import { getCiudades } from '../../dataLoad/dataInit'
import SearchInput from '@components/inputs/Searchi'
import { categoriasItems  } from '../../reducers/categoriaSlice'
import Select from '@components/selects/Select'
import Loading from '@components/snippets/Loading'

const ClientesInicio = () => {
    const dispatch = useDispatch()  
    const navigate = useNavigate()   
    const { loading, data, pagina, paginas, total, lciudad } = useSelector((state) => state.clientes)                    
    const { c_items } = useSelector((state) => state.categoria)  
    const [indicador, setindicador] = useState(0);  
    const [viewx, setviewx] = useState(false); 
    const [viewy, setviewy] = useState(false);
    const [view, setview] = useState(false);    
    const [ciudades, setciudades] = useState([]);
    const [ciudad, setciudad] = useState("");  
    const [nit, setnit] = useState(1); 
    const [parametro, setparametro] = useState("");
    const xlatitude  = JSON.parse(localStorage.getItem('@xlatitude23')) 
    const xlongitude = JSON.parse(localStorage.getItem('@xlongitude23'))

   
    const getDatas = (pag) =>{
      setindicador(0)
      let iok={
        latitude    : xlatitude,
        longitude   : xlongitude,
        parametro   :  "", 
        pagina      :  pag,
        num         :  12,
        prop        :  "tags",
        categoriaId :  0  
      }
      dispatch(clientesData(iok))
    }

    const fetchCurrentLocation = () => {
      let newCiudades = getCiudades(1)
      setciudades(newCiudades)
      dispatch(categoriasItems())  
    }

    useEffect(() => {    
      fetchCurrentLocation()      
      return () => {          
      };
   }, []);

    useEffect(() => {
      if(xlatitude && xlongitude){
        getDatas(1)    
      }    
    }, [lciudad]);


  
   const handleBorrar = () =>{

   }
   const handleChangx = (prp) =>{
    const { latitude, longitude, label } = prp      
        localStorage.setItem("@xlatitude23", JSON.stringify(latitude));    
        localStorage.setItem("@xlongitude23", JSON.stringify(longitude));
        dispatch(setLciudad(label))     
        setciudad(label)   
        let iok={
            latitude    : latitude,
            longitude   : longitude,
            parametro   : "", 
            pagina      : 1,
            num         : 12,
            prop        : "tags",
            categoriaId : 0  
            }
        dispatch(clientesData(iok))
   }
   const handleSearch = event =>{
	   event.preventDefault();
    setindicador(0)
      let iok={
        latitude    : xlatitude,
        longitude   : xlongitude,
        parametro   :  parametro,
        pagina      :  1,
        num         :  12,
        prop        :  "tags",
        categoriaId :  0
      }
      dispatch(clientesData(iok))
    
   }
   const handleParametro = () =>{
    setindicador(0)
    let iok={
      latitude    : xlatitude,
      longitude   : xlongitude,
      parametro   :  "", 
      pagina      :  1,
      num         :  12,
      prop        :  "tags",
      categoriaId :  0  
    }
    dispatch(clientesData(iok))
   }
   const handleClica = (nn) =>{
    const {label , value } = nn
        let iok={
          latitude    : xlatitude,
          longitude   : xlongitude,
          parametro   :  "", 
          pagina      :  1,
          num         :  12,
          prop        :  "tags",
          categoriaId :  value 
        }
        dispatch(clientesData(iok))   
        setnit(value) 
   }
   const getData = (pag) =>{
    setindicador(0)
      let iok={
        latitude    : xlatitude,
        longitude   : xlongitude,
        parametro   :  "", 
        pagina      :  pag,
        num         :  12,
        prop        :  "tags",
        categoriaId :  0  
      }
      dispatch(clientesData(iok))
   }
  
   const setIndicador = (key) =>{             
    if(key){
      let iok={
        id: key,
        clienteId : key,
        tip: 'unit',
        latitude    : -17.781679304587538,
        longitude   : -63.17918751660203,
      }
    dispatch(clientesItem(iok))
    setview(true)      
    }      
  }

const setPay = (key) =>{       
  setindicador(key)        
  dispatch(catesItems({pky:key}))     
  dispatch(productosData({pagina:1,num:12,value:"",categoriaId:0,clienteId:key}))
  setviewy(true)
  dispatch(setInd(key))
}

const setPays = (key) =>{       
  dispatch(catesItems({pky:key}))
  dispatch(clienteItem({id:key}))      
  dispatch(productosData({pagina:1,num:12,value:"",categoriaId:0,clienteId:key}))  
  navigate('/tpdv');  
}
  
  return (   
    <>                        
    <div className="h-max w-full bg-gray-100 flex-1">
        <div className='h-11 flex border bg-gray-200'>
            <div className='w-8/12 flex items-center pl-1'>
                        { c_items.map((ite,index)=>(
                            <button
                            key={index}
                            onClick={()=> handleClica(ite)}
                            className={ite.value === nit ? 'h-7 pl-2 pr-2 font-bold text-[11px] text-left  border  bg-[#015eea] hover:bg-blue-400 text-gray-100':'h-7 pl-2 pr-2 font-bold text-[11px] text-left border-r border-t border-b border-gray-300  bg-gray-200 hover:bg-gray-100 text-gray-500' }>
                                {ite.label}
                            </button>    
                        ))} 
            </div>     
            <div className='w-2/12 flex items-center'>                                 
                <SearchInput
                  parametro={parametro}
                  setParametro={setparametro}
                  handleSearch={handleSearch}
                  handleParametro={handleParametro}/>
            </div>            
            <div className='w-2/12 flex items-center'>                                 
                  <div className='w-full pl-1'>
                        <Select
                          options={ciudades}
                          option={ciudad}                                    
                          handleChange={handleChangx} 
                          name={"ciudad"}
                          tipo={"location"}                          
                          />  
                      </div> 
            </div>            
        </div> 
        <div className='h-max flex'>  
          <div className='h-580 w-full flex flex-col border border-gray-300 '>
          <Table 
                    data={data}                
                    setIndicador={setIndicador}
                    setPay={setPay}
                    setPays={setPays}
                    getData={getData}
                    pagina={pagina}
                    paginas={paginas}
                    total={total}/>
          </div>                                
        </div>
    </div>
    <ModalCliente view={view} setview={setview} />     
    <ModalPay view={viewy} setview={setviewy} />
    { viewx ?
        <>
        <div className="justify-center items-center flex-1 fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-7 mx-auto max-w-xs flex-row justify-between">
                <div className='h-12 flex w-full  border-t border-b border-gray-300 bg-white'>
                    <div className='w-3/12 flex items-center pl-2'>  
                        <button
                        onClick={() => setviewx(false)}
                        className='w-7 flex h-7 rounded-full bg-red-400 hover:bg-red-300 justify-center items-center'>
                            <XMarkIcon className="h-5 w-5 text-gray-50" />
                        </button>              
                    </div>                                                              
                </div>
                <div className="h-200  bg-white rounded-b  shadow-lg relative flex flex-col w-full outline-none focus:outline-none p-1">                          
                    <h6 className='text-[11px] font-bold pl-4 mt-2 text-gray-500'>Selecciona la ciudad</h6>  
                    <div className="w-full flex-col p-4">
                      <label htmlFor="ciudad" className="h-7 text-[11px] flex p-1 font-bold text-gray-500">Ciudad</label>
                      <div className='w-full'>
                        <Select
                          options={ciudades}
                          option={ciudad}                                    
                          handleChange={handleChangx} 
                          name={"ciudad"}
                          tipo={"location"}                          
                          />  
                      </div>    
                    </div>                       
                </div>  
            </div>  
        </div>
    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    : null  }


    
  
    <Loading loading={loading}/>
   </>  
  );
}

export default ClientesInicio;
